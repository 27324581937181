var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-3" },
    [
      _c("b-row", { staticClass: "mt-1" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-alert",
              {
                attrs: { variant: "danger", dismissible: "", fade: "" },
                model: {
                  value: _vm.showDismissibleAlert,
                  callback: function ($$v) {
                    _vm.showDismissibleAlert = $$v
                  },
                  expression: "showDismissibleAlert",
                },
              },
              [
                _vm._v(
                  "\n        ALCUNE FORME DI PAGAMENTO IMPORTATE NON SONO ANCORA MAPPATE, I VALORI\n        DELLA CONTABILITA' POTREBBERO ESSERE INCOERENTI FINO ALLA MAPPATURA DI\n        TUTTE LE FORME DI PAGAMENTO\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("b-row", [
        _c(
          "div",
          { staticClass: "col-md-9 p-0" },
          [
            _c(
              "b-overlay",
              {
                attrs: { center: "", show: _vm.eventsLoading, rounded: "sm" },
                scopedSlots: _vm._u([
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "b-card",
                  { staticClass: "calendar" },
                  [
                    _c("full-calendar", {
                      ref: "fullCalendar",
                      staticClass: "fc",
                      attrs: { options: _vm.calendarOptions },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3 pt-3" },
          [
            _c(
              "b-overlay",
              {
                attrs: { center: "", show: _vm.tasksLoading, rounded: "sm" },
                scopedSlots: _vm._u([
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { align: "left" } }, [
                      _c("h5", [_vm._v("Elenco attività")]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { align: "right" } },
                      [
                        _vm.canVerb("tasks", "store")
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "button",
                                  variant: "primary",
                                  size: "sm",
                                  title: "Crea",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.createTask(1)
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "plus-circle",
                                    "aria-hidden": "true",
                                  },
                                }),
                                _vm._v("\n              Crea"),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "task mb-2" },
                  _vm._l(_vm.tasks, function (task, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "b-card",
                          {
                            class: `mt-2 mr-2 ${_vm.getClassNames(task)}`,
                            staticStyle: {
                              "max-width": "104px",
                              cursor: "pointer",
                            },
                            attrs: { tag: "article", title: task.title },
                            on: {
                              click: function ($event) {
                                return _vm.viewTask(task)
                              },
                            },
                          },
                          [
                            _c("b-card-text", [
                              _vm._v(
                                _vm._s(
                                  `${
                                    task.description
                                      ? task.description.slice(0, 30)
                                      : ""
                                  }...`
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _vm.canModule("module.HOME.LAPP") && false
            ? _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "chevron-up", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "chevron-down",
                              "font-scale": "1.5",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Esporta File Lisa4App\n      "),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c(
            "b-collapse",
            { attrs: { id: "collapse-1" } },
            [
              _c(
                "b-row",
                { staticClass: "mb-5" },
                [
                  _c(
                    "b-col",
                    { attrs: { align: "left" } },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1",
                              arg: "collapse-1",
                            },
                          ],
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: !_vm.hasLisaForAppPermission()
                              ? "Scarica File Unoone Polizze"
                              : "Funzione non abilitata",
                            disabled:
                              _vm.hasLisaForAppPermission() ||
                              _vm.isDownloading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onDownload("PTRAAUTO")
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "clipboard-data",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" Scarica\n          File Unoone Polizze"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1",
                              arg: "collapse-1",
                            },
                          ],
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: !_vm.hasLisaForAppPermission()
                              ? "Scarica File Unoone Dati Tecnici"
                              : "Funzione non abilitata",
                            disabled:
                              _vm.hasLisaForAppPermission() ||
                              _vm.isDownloading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onDownload("PTRAEXAU")
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "tools", "aria-hidden": "true" },
                          }),
                          _vm._v("Scarica File\n          Unoone Dati Tecnici"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1",
                              arg: "collapse-1",
                            },
                          ],
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: !_vm.hasLisaForAppPermission()
                              ? "Scarica File Unoone Titoli"
                              : "Funzione non abilitata",
                            disabled:
                              _vm.hasLisaForAppPermission() ||
                              _vm.isDownloading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onDownload("PTRAVITA")
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "journals", "aria-hidden": "true" },
                          }),
                          _vm._v(" Scarica File\n          Unoone Titoli"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.formLoaded
        ? _c(
            "div",
            [
              _c("event-create-modal", {
                attrs: {
                  beFormCreate: _vm.beCreateForm["task"],
                  beRulesCreate: _vm.beRules["task"],
                },
                on: { cancel: _vm.cancelEventCreation, create: _vm.storeEvent },
                model: {
                  value: _vm.evt,
                  callback: function ($$v) {
                    _vm.evt = $$v
                  },
                  expression: "evt",
                },
              }),
              _vm._v(" "),
              _c("event-show-modal", {
                attrs: { selectInfo: _vm.inf },
                on: { delete: _vm.deleteEvent, change: _vm.handleChangeClick },
              }),
              _vm._v(" "),
              _c("event-change-modal", {
                attrs: {
                  beFormUpdate: _vm.beEditForm["task"],
                  beRulesUpdate: _vm.beRules["task"],
                },
                on: { cancel: _vm.cancelEventChange, update: _vm.putEvent },
                model: {
                  value: _vm.evt,
                  callback: function ($$v) {
                    _vm.evt = $$v
                  },
                  expression: "evt",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }